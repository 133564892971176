import React, { useRef, useEffect } from 'react';
import { PictureProps } from '@soluto-design/react';
import cls from '@soluto-design/styles/cls';

import { MediaProps } from '../../molecules/Media';
import VideoWrapper, {
    VideoWrapperProps,
    VideoWrapperRef,
} from '../VideoWrapper';

export interface YoutubeVideoProps {
    width?: number;
    height?: number;
    poster?: Partial<PictureProps>;
    videoId: string;
    title: string;
    layout?: MediaProps['layout'];
    className?: VideoWrapperProps['className'];
    showTitle?: boolean;
    startOnClick?: boolean;
    autoPlay?: boolean;
    muted?: boolean;
    onLoaded?: (player: any) => void;
    onEnd?: () => void;
}

export default function YoutubeVideo({
    width,
    height,
    poster,
    videoId,
    title,
    layout = 'responsive',
    className,
    showTitle,
    startOnClick,
    muted = false,
    onLoaded,
    onEnd,
}: YoutubeVideoProps) {
    const wrapperRef = useRef<VideoWrapperRef>(null);
    const containerRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        if (
            typeof window === 'undefined' ||
            !wrapperRef.current ||
            !containerRef.current
        )
            return;
        // Load the YouTube IFrame API script if it hasn't been loaded yet
        if (!window.YT) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            // Setup the callback function that the YouTube API will call
            window.onYouTubeIframeAPIReady = initializePlayer;
        } else {
            // If the API is already loaded, just initialize the player
            initializePlayer();
        }

        function initializePlayer() {
            playerRef.current = new window.YT.Player(containerRef.current, {
                videoId: videoId,
                height: height,
                width: width,
                playerVars: {
                    color: 'white',
                    rel: 0,
                    showinfo: 0,
                    modestbranding: 1,
                    mute: muted ? 1 : 0,
                },
                events: {
                    onReady: (event) => {
                        typeof onLoaded === 'function' &&
                            onLoaded(event.target);
                    },
                    onStateChange: (event) => {
                        switch (event.data) {
                            case window.YT.PlayerState.ENDED:
                                typeof onEnd === 'function' && onEnd();
                                break;
                            case window.YT.PlayerState.PLAYING:
                                wrapperRef.current?.setPlayed(true);
                                break;
                            default:
                                break;
                        }
                    },
                },
            });

            setTimeout(() => {
                const iframe = playerRef.current.getIframe();
                if (iframe.dataset.src && !iframe.src.includes('youtube')) {
                    iframe.src = iframe.dataset.src;
                }
            }, 0);
        }

        // Cleanup function
        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    }, [videoId, width, height, muted, wrapperRef, containerRef]); // Re-run if videoId changes

    const playVideo = () => {
        if (!playerRef.current) return;
        playerRef.current.playVideo();
    };

    return (
        <VideoWrapper
            ref={wrapperRef}
            layout={layout}
            onClickStart={playVideo}
            {...(!!poster?.src && {
                showPlayButton: true,
                showTitle,
                startOnClick,
            })}
            title={title}
            poster={poster}
            className={className}
            {...(layout === 'responsive' &&
                height &&
                width && {
                    style: { paddingTop: `${(height / width) * 100}%` },
                })}>
            <div
                ref={containerRef}
                className={cls('absolute inset-0 w-full h-full')}
            />
        </VideoWrapper>
    );
}
