import React, {
    forwardRef,
    useEffect,
    useState,
    useRef,
    useCallback,
    useImperativeHandle,
} from 'react';
import { Button, Text } from '@soluto-design/react';

import Modal, { ModalRef } from '../../molecules/Modal';

import YoutubeVideo from '../../atoms/YoutubeVideo';

import { navigate } from '@soluto-design/link';

export type ModalVideoRef = ModalRef;

export interface ModalVideoProps {
    description?: string;
    cta?: {
        children?: string;
        label?: string;
        href: string;
    };
    videoId: string;
    thumbnail: string;
    closeModalLabel?: string;
    alternativeCtaLabel?: string;
    autoplay?: boolean;
    onCloseModal?: () => void;
    isModalOpen: boolean;
    autoPlay?: boolean;
}

const ModalVideo = forwardRef<ModalVideoRef, ModalVideoProps>(
    function ModalVideo(
        {
            description,
            cta,
            videoId,
            thumbnail,
            closeModalLabel,
            alternativeCtaLabel,
            onCloseModal,
            autoPlay,
        },
        ref,
    ) {
        const [isEnded, setIsEnded] = useState(false);
        const [open, setOpen] = useState(false);
        const [isLoaded, setLoaded] = useState(false);
        const firstOpen = useRef(true);

        const modalRef = useRef<ModalRef>(null);
        const playerRef = useRef<any>(null);

        const thisOnClose = useCallback(() => {
            if (playerRef?.current) {
                playerRef.current.pauseVideo();
            }

            if (typeof onCloseModal === 'function') {
                onCloseModal();
            }
        }, [onCloseModal]);

        const close = useCallback(() => {
            setOpen(false);
            modalRef.current?.close();
        }, []);

        const handleClickCta = useCallback((event) => {
            event.preventDefault();
            setOpen(false);
            modalRef.current?.close();

            setTimeout(() => {
                navigate(event.target.href);
            }, 50);
        }, []);

        useEffect(() => {
            if (
                !playerRef?.current ||
                !open ||
                !isLoaded ||
                !firstOpen.current ||
                !autoPlay
            ) {
                return;
            }

            const player = playerRef.current;

            player.playVideo();

            setTimeout(() => {
                player.unMute();
            }, 50);

            firstOpen.current = false;
        }, [isLoaded, open, autoPlay]);

        useImperativeHandle(
            ref,
            () => ({
                open: () => {
                    setOpen(true);
                    modalRef.current?.open();
                },
                close,
                toggle: () => {
                    if (open) {
                        setOpen(false);
                        modalRef.current?.close();
                        return;
                    }
                    setOpen(true);
                    modalRef.current?.open();
                },
            }),
            [open, close],
        );

        return (
            <Modal
                id="modal-video"
                ref={modalRef}
                open={open}
                fullscreen
                onClose={thisOnClose}>
                <div
                    id="modal-header"
                    className="flex justify-between gap-x-gutter flex-initial pb-lg items-center">
                    <Text component="div" className="text-h400" isHtml>
                        {description}
                    </Text>
                    <div className="flex gap-x-gutter items-start">
                        {cta && (
                            <Button
                                href={cta?.href}
                                className="hidden lg:inline-flex"
                                size="large"
                                onClick={handleClickCta}
                                variant="primary">
                                {isEnded
                                    ? alternativeCtaLabel ??
                                      cta?.children ??
                                      cta?.label
                                    : cta?.children ?? cta?.label}
                            </Button>
                        )}
                        <Button
                            variant="secondary"
                            size="icon_large"
                            className={'cursor-pointer [&>svg]:!h-24'}
                            data-dismiss="modal"
                            onClick={close}
                            aria-label="Close"
                            leftIcon={'x'}>
                            <span className="sr-only">{closeModalLabel}</span>
                        </Button>
                    </div>
                </div>
                <div
                    id="modal-body"
                    className="flex-1 flex flex-col justify-center items-start md:justify-start xl:justify-center pt-lg max-h-full">
                    <div className="relative [&>iframe]:absolute [&>iframe]:inset-0 [&>iframe]:object-fill [&>iframe]:w-full [&>iframe]:h-full aspect-video rounded-large bg-background-tertiary overflow-hidden h-auto w-full xl:h-full xl:aspect-auto transition-all duration-300 ease-linear">
                        <YoutubeVideo
                            videoId={videoId}
                            title={description as string}
                            layout="fill"
                            showTitle={false}
                            startOnClick={false}
                            muted
                            onLoaded={(player) => {
                                playerRef.current = player;
                                setLoaded(true);
                            }}
                            className="absolute inset-0 w-full h-full"
                            poster={{
                                src: thumbnail,
                                alt: description,
                            }}
                            onEnd={() => {
                                setIsEnded(true);
                            }}
                        />
                    </div>
                    {cta && (
                        <Button
                            href={cta?.href}
                            className="mt-48 w-full lg:hidden"
                            size="large"
                            onClick={handleClickCta}
                            variant="primary">
                            {isEnded
                                ? alternativeCtaLabel ??
                                  cta?.children ??
                                  cta?.label
                                : cta?.children ?? cta?.label}
                        </Button>
                    )}
                </div>
            </Modal>
        );
    },
);

export default ModalVideo;
